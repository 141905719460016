import { css } from 'styled-components';
import { getBackgroundStyle } from '../../../utils/utilityFunction';
import breakpoints from '../../../themes/breakpoints';
var styles = css(["", ";.textCard{padding:0 20px;text-align:center;.textCardContent{display:inline-block;margin-bottom:20px;ul{li{display:block;p{display:list-item;list-style-type:'\u2022  ';list-style-position:inside;}}}ol{li{display:block;p{display:list-item;list-style-position:inside;}}}}.heading_class{margin-bottom:10px;}.subheading_class{margin-bottom:20px;}.para_class{p{margin-bottom:40px;}}}"], function (props) {
  var _props$theme, _props$theme2, _props$theme3;
  var overrideBackground = props.overrideBackground;
  var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
  var backgroundColor = (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.imageSlider.primaryBgColor;
  return getBackgroundStyle(overrideId, '', backgroundColor, (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.textures);
});
export default styles;