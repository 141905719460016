import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { imageRenditions } from './config';
import fontColor from '../../../constants/globalConstants';
import { setNonCtaAnchorStyling, undoSettingOfNonCtaAnchorStyling } from '../../../utils/utilityFunction';
var styles = css(["padding-top:20px;", " .service-tile-container{display:block;@media (min-width:", "){display:flex;flex-wrap:wrap;", "}}.service-text{padding:25px 25px 0 20px;p + ul,p + ol{margin-top:0;}h2,h3,h4,ul,ol,p{color:", ";}a:not(.sg-btn-cpnt),span.fakeAnchor:not(.sg-btn-cpnt){", " &:hover{text-decoration:none;color:", ";}}p{margin-bottom:0;", " > a:not(.sg-btn-cpnt),span.fakeAnchor:not(.sg-btn-cpnt){", " ", " ", " &:hover{color:", ";text-decoration:underline;}}}.card-header{margin-bottom:10px;a:not(.sg-btn-cpnt){line-height:1;color:", ";&:hover{color:", ";}}}@media (min-width:", "){padding:25px 30px 0 20px;}@media (min-width:", "){padding:0 20px;max-width:60%;}@media (min-width:", "){padding:0 23px 0 30px;}}img{", " margin:0;@media (min-width:", "){margin:0 auto;display:block;}}.blade-heading{display:block;margin:20px 20px 10px;}.blade-description{margin:10px auto 20px;display:table;padding:0 20px;p + ul,p + ol{margin-top:0;}h2,h3,h4,ul,ol,p{color:", ";}p{margin:0;padding-bottom:10px;}ul{li{display:block;p{display:list-item;list-style-type:'\u2022  ';list-style-position:inside;}}}ol{li{display:block;p{display:list-item;list-style-position:inside;}}}@media (min-width:", "){", ";}@media (min-width:", "){margin:10px auto ", ";}@media (min-width:", "){padding:0;}}.blade-heading,.blade-description{text-align:center;color:", ";a:not(.sg-btn-cpnt){", "}}.service-tile{display:block;padding:5px 0 ", ";&:hover{.card-header{color:", ";}}.card-rich-text{p{margin-bottom:10px;}a:not(.sg-btn-cpnt){display:inline;}}@media (max-width:", "){", "}@media (min-width:", "){padding:5px 224px ", ";}@media (min-width:", "){margin-bottom:", ";display:flex;padding:0;width:50%;}}@media (min-width:", "){padding:20px 30px;}@media (min-width:", "){padding:60px 148px 20px;}"], function (props) {
  var _props$theme, _props$theme2, _props$theme3, _props$theme4;
  return (_props$theme = props.theme) !== null && _props$theme !== void 0 && _props$theme.service.alternatingBgColor ? ":nth-of-type(even) {\n      background-color: ".concat((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.service.bgColorBlade, ";\n    }\n    :nth-of-type(odd) {\n      background-color: ").concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.service.alternateBgColorBlade, ";\n    }") : "background-color: ".concat((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.service.bgColorBlade, ";");
}, breakPoints.desktop, function (props) {
  var _props$theme5, _props$theme6;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && _props$theme5.service.containerserviceTileBottomMargin ? "margin: ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.service.containerserviceTileBottomMargin, ";") : '';
}, function (props) {
  var _props$theme7, _props$theme8;
  return props && props.servicesBladeData && props.servicesBladeData.fontColor === fontColor.light ? (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.service.textColorLight : (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.service.textColorDark;
}, function (props) {
  var _props$servicesBladeD, _props$theme9, _props$theme10, _props$theme11;
  return (props === null || props === void 0 ? void 0 : (_props$servicesBladeD = props.servicesBladeData) === null || _props$servicesBladeD === void 0 ? void 0 : _props$servicesBladeD.fontColor) === fontColor.light && (_props$theme9 = props.theme) !== null && _props$theme9 !== void 0 && _props$theme9.service ? setNonCtaAnchorStyling((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.service) : undoSettingOfNonCtaAnchorStyling((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.richText.anchorColor);
}, function (props) {
  var _props$theme12, _props$theme13;
  return ((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : _props$theme12.service.linkHoverColor) || ((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.service.linkColor);
}, function (props) {
  var _props$theme14, _props$theme15;
  return (_props$theme14 = props.theme) !== null && _props$theme14 !== void 0 && _props$theme14.service.paragraphLineHeight ? "line-height: ".concat((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : _props$theme15.service.paragraphLineHeight, ";") : '';
}, function (props) {
  var _props$theme16;
  return (_props$theme16 = props.theme) !== null && _props$theme16 !== void 0 && _props$theme16.service.linkUppercase ? "text-transform: uppercase;" : '';
}, function (props) {
  var _props$theme17;
  return (_props$theme17 = props.theme) !== null && _props$theme17 !== void 0 && _props$theme17.service.linkLeftAligned ? "text-align: left;" : '';
}, function (props) {
  var _props$servicesBladeD2, _props$theme18, _props$theme19, _props$theme20;
  return (props === null || props === void 0 ? void 0 : (_props$servicesBladeD2 = props.servicesBladeData) === null || _props$servicesBladeD2 === void 0 ? void 0 : _props$servicesBladeD2.fontColor) === fontColor.light && (_props$theme18 = props.theme) !== null && _props$theme18 !== void 0 && _props$theme18.service ? setNonCtaAnchorStyling((_props$theme19 = props.theme) === null || _props$theme19 === void 0 ? void 0 : _props$theme19.service) : undoSettingOfNonCtaAnchorStyling((_props$theme20 = props.theme) === null || _props$theme20 === void 0 ? void 0 : _props$theme20.service.linkColor);
}, function (props) {
  var _props$theme21, _props$theme22;
  return ((_props$theme21 = props.theme) === null || _props$theme21 === void 0 ? void 0 : _props$theme21.service.linkHoverColor) || ((_props$theme22 = props.theme) === null || _props$theme22 === void 0 ? void 0 : _props$theme22.service.linkColor);
}, function (props) {
  var _props$theme23;
  return props && props.servicesBladeData && props.servicesBladeData.fontColor === fontColor.light ? (_props$theme23 = props.theme) === null || _props$theme23 === void 0 ? void 0 : _props$theme23.service.textColorLight : 'inherit';
}, function (props) {
  var _props$theme24, _props$theme25;
  return ((_props$theme24 = props.theme) === null || _props$theme24 === void 0 ? void 0 : _props$theme24.service.linkHoverColor) || ((_props$theme25 = props.theme) === null || _props$theme25 === void 0 ? void 0 : _props$theme25.service.linkColor);
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.desktopM, function (props) {
  var _props$theme26, _props$theme27;
  return (_props$theme26 = props.theme) !== null && _props$theme26 !== void 0 && _props$theme26.service.cardRadius ? "border-radius: ".concat((_props$theme27 = props.theme) === null || _props$theme27 === void 0 ? void 0 : _props$theme27.service.cardRadius, ";") : '';
}, breakPoints.mobileSm, function (props) {
  var _props$theme28, _props$theme29;
  return props && props.servicesBladeData && props.servicesBladeData.fontColor === fontColor.light ? (_props$theme28 = props.theme) === null || _props$theme28 === void 0 ? void 0 : _props$theme28.service.textColorLight : (_props$theme29 = props.theme) === null || _props$theme29 === void 0 ? void 0 : _props$theme29.service.textColorDark;
}, breakPoints.mobileSm, function (props) {
  var _props$theme30, _props$theme31;
  return (_props$theme30 = props.theme) !== null && _props$theme30 !== void 0 && _props$theme30.service.serviceTileBottomMargin ? "margin: 0 auto ".concat((_props$theme31 = props.theme) === null || _props$theme31 === void 0 ? void 0 : _props$theme31.service.serviceTileBottomMargin) : '';
}, breakPoints.desktop, function (props) {
  var _props$theme32, _props$theme33;
  return (_props$theme32 = props.theme) !== null && _props$theme32 !== void 0 && _props$theme32.service.serviceTileBottomMargin ? "".concat((_props$theme33 = props.theme) === null || _props$theme33 === void 0 ? void 0 : _props$theme33.service.serviceTileBottomMargin) : "50px";
}, breakPoints.desktop, function (props) {
  var _props$theme34, _props$theme35;
  return props && props.servicesBladeData && props.servicesBladeData.fontColor === fontColor.light ? (_props$theme34 = props.theme) === null || _props$theme34 === void 0 ? void 0 : _props$theme34.service.textColorLight : (_props$theme35 = props.theme) === null || _props$theme35 === void 0 ? void 0 : _props$theme35.service.textColorDark;
}, function (props) {
  var _props$servicesBladeD3, _props$theme36, _props$theme37, _props$theme38;
  return (props === null || props === void 0 ? void 0 : (_props$servicesBladeD3 = props.servicesBladeData) === null || _props$servicesBladeD3 === void 0 ? void 0 : _props$servicesBladeD3.fontColor) === fontColor.light && (_props$theme36 = props.theme) !== null && _props$theme36 !== void 0 && _props$theme36.service ? setNonCtaAnchorStyling((_props$theme37 = props.theme) === null || _props$theme37 === void 0 ? void 0 : _props$theme37.service) : undoSettingOfNonCtaAnchorStyling((_props$theme38 = props.theme) === null || _props$theme38 === void 0 ? void 0 : _props$theme38.richText.anchorColor);
}, function (props) {
  var _props$theme39, _props$theme40;
  return (_props$theme39 = props.theme) !== null && _props$theme39 !== void 0 && _props$theme39.service.serviceTileMobileBottomMargin ? "".concat((_props$theme40 = props.theme) === null || _props$theme40 === void 0 ? void 0 : _props$theme40.service.serviceTileMobileBottomMargin) : "30px";
}, function (props) {
  var _props$theme41, _props$theme41$button;
  return (_props$theme41 = props.theme) === null || _props$theme41 === void 0 ? void 0 : (_props$theme41$button = _props$theme41.button) === null || _props$theme41$button === void 0 ? void 0 : _props$theme41$button.backgroundColorHoverHero;
}, breakPoints.mobile, function (props) {
  var _props$theme42;
  return (_props$theme42 = props.theme) !== null && _props$theme42 !== void 0 && _props$theme42.service.addSidePaddingToImageOnMobile ? ".image-wrapper {\n        padding-left: 15px;\n        padding-right: 15px;\n      }" : '';
}, breakPoints.tabletP, function (props) {
  var _props$theme43, _props$theme44;
  return (_props$theme43 = props.theme) !== null && _props$theme43 !== void 0 && _props$theme43.service.serviceTileMobileBottomMargin ? "".concat((_props$theme44 = props.theme) === null || _props$theme44 === void 0 ? void 0 : _props$theme44.service.serviceTileMobileBottomMargin) : "10px";
}, breakPoints.desktop, function (props) {
  var _props$theme45, _props$theme46;
  return (_props$theme45 = props.theme) !== null && _props$theme45 !== void 0 && _props$theme45.service.serviceTileBottomMargin ? (_props$theme46 = props.theme) === null || _props$theme46 === void 0 ? void 0 : _props$theme46.service.serviceTileBottomMargin : '40px';
}, breakPoints.desktop, breakPoints.desktopM);
export default styles;