import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "promoData"];
var __jsx = React.createElement;
import React from 'react';
import ImageTextCard from '../../molecules/ImageTextCard';
import styled from 'styled-components';
import styles from './PromoBanner.style';
import { fetchProperty } from '../../../utils/utilityFunction';
import constants, { siteConstants } from '../../../constants/globalConstants';
var PromoBannerSection = styled.section.withConfig({
  componentId: "sc-15orjhq-0"
})(["", ";"], styles);
var PromoBanner = function PromoBanner(_ref) {
  var _textBlock$links, _textBlock$links$asse;
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    promoData = _ref.promoData,
    theme = _objectWithoutProperties(_ref, _excluded);
  var textBlock = promoData.textBlock,
    cta = promoData.cta,
    multipleCtaCollection = promoData.multipleCtaCollection;
  var richTextEmbeddedAsset = textBlock && (textBlock === null || textBlock === void 0 ? void 0 : textBlock.links) && (textBlock === null || textBlock === void 0 ? void 0 : (_textBlock$links = textBlock.links) === null || _textBlock$links === void 0 ? void 0 : (_textBlock$links$asse = _textBlock$links.assets) === null || _textBlock$links$asse === void 0 ? void 0 : _textBlock$links$asse.block) || {};
  var description = textBlock && textBlock !== null && textBlock !== void 0 && textBlock.json ? textBlock === null || textBlock === void 0 ? void 0 : textBlock.json : false;
  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (!description) {
    description = textBlock ? textBlock === null || textBlock === void 0 ? void 0 : textBlock.textBlock : '';
  }
  var anchorLink = promoData && (promoData === null || promoData === void 0 ? void 0 : promoData.anchorLink) || '';
  return __jsx(PromoBannerSection, {
    className: className,
    id: anchorLink
  }, __jsx(ImageTextCard, {
    styleType: "promo-banner",
    className: "promo-banner",
    paraClassName: "container",
    ariaLabel: fetchProperty(promoData, 'headline'),
    headingText: fetchProperty(promoData, 'headline'),
    headingType: "h2",
    subParaText: fetchProperty(promoData, 'subheadline'),
    subParaClassName: "heading_four",
    descriptionType: siteConstants.RICH_TEXT,
    descriptionText: description,
    richTextEmbeddedAsset: richTextEmbeddedAsset,
    ctaCollection: multipleCtaCollection,
    ctaURL: cta && cta.url ? cta.url : '',
    ctaCopy: cta && cta.label ? cta.label : '',
    ctaBehavior: cta && cta.behavior ? cta.behavior : '',
    ctaHyperlinkType: cta && cta.hyperlinkType ? cta.hyperlinkType : '',
    linkClassName: "promo-link",
    linkStyleType: "primary-anchor",
    dataAnalytics: {
      type: constants.analytics.analyticsType.promoBannerCTA,
      value: fetchProperty(promoData, 'headline')
    }
  }));
};
PromoBanner.defaultProps = {
  className: '',
  ariaLabel: ''
};
export default styled(PromoBanner).withConfig({
  componentId: "sc-15orjhq-1"
})(["", ";"], styles);