import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "contactUsData"];
var __jsx = React.createElement;
/**
 * Represents Contact Us.
 * @author kkum
 */import React from 'react';
import ContactUs from '../../molecules/ContactUs';
var ContactUsForm = function ContactUsForm(_ref) {
  var className = _ref.className,
    contactUsData = _ref.contactUsData,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!contactUsData) {
    return null;
  }
  return __jsx(ContactUs, {
    contactUsData: contactUsData
  });
};
ContactUsForm.defaultProps = {
  className: ''
};
export default ContactUsForm;