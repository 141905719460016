import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var ReviewCardStyles = css(["background:", ";box-sizing:border-box;text-align:left;position:relative;border-radius:", ";svg{width:45px;height:32px;fill:", ";}p{margin:20px 0 10px;@media (min-width:", "){margin:30px 0 10px;}}a{color:", ";font-size:inherit;letter-spacing:inherit;line-height:inherit;&:hover{text-decoration:underline;color:", ";}}.reviewer-container{margin-top:auto;width:76px;overflow:hidden;display:flex;flex-direction:column;padding-top:", ";@media (max-width:", "){", "}@media (min-width:", "){padding-top:50px;}}.heading_five{margin:0;padding-top:10px;color:", ";}@media (max-width:", "){padding:20px;margin-left:auto;margin-right:auto;width:", ";", " &:not(:last-of-type){margin-bottom:", ";}}@media (min-width:", "){padding:30px;display:flex;flex-direction:column;vertical-align:middle;width:calc(33.33% - 10px);max-width:308px;&:not(:last-of-type){margin-right:", ";}}@media (min-width:", "){padding:30px;display:flex;flex-direction:column;vertical-align:middle;width:calc(33.33% - 10px);max-width:337px;&:not(:last-of-type){margin-right:", ";}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.reviewCard.cardBgColor;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.reviewCard.borderRadius;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.reviewCard.reviewLinkColor;
}, breakPoints.desktop, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.reviewCard.reviewLinkColor;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.reviewCard.reviewLinkHoverColor;
}, function (props) {
  var _props$theme6;
  return ((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.reviewCard.reviewerContainerMobileTopPadding) || "80px";
}, breakPoints.mobile, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) !== null && _props$theme7 !== void 0 && _props$theme7.reviewCard.hideReviewerPicture ? "picture {\n        display: none;\n      }" : '';
}, breakPoints.desktop, function (props) {
  var _props$theme8;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.reviewCard.reviewTextColor;
}, breakPoints.tabletL, function (props) {
  var _props$theme9;
  return ((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.reviewCard.tabletCardWidth) || "280px";
}, function (props) {
  var _props$theme10, _props$theme11;
  return (_props$theme10 = props.theme) !== null && _props$theme10 !== void 0 && _props$theme10.reviewCard.mobileMaxWidth ? "max-width: ".concat((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.reviewCard.mobileMaxWidth, ";") : '';
}, function (props) {
  var _props$theme12;
  return ((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : _props$theme12.reviewCard.containerMarginBottom) || "10px";
}, breakPoints.desktop, function (props) {
  var _props$theme13;
  return ((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.reviewCard.containerMarginRight) || "15px";
}, breakPoints.desktopM, function (props) {
  var _props$theme14;
  return ((_props$theme14 = props.theme) === null || _props$theme14 === void 0 ? void 0 : _props$theme14.reviewCard.containerMarginRight) || "15px";
});
export default ReviewCardStyles;