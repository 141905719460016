/**
 * @file Manages styles for Header atom.
 * @author kkumari
 */
import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
export default css(["padding:40px 20px;background-color:", ";label{color:", ";}#ohhoneyname,label[for='ohhoneyname']{opacity:0;position:absolute;top:0;left:0;height:0;width:0;z-index:-1;color:", ";}.form-heading,.success-message,.error-message{text-align:center;width:100%;margin-bottom:30px;@media (min-width:", "){margin-bottom:80px;}}input{background-color:", ";border-radius:0;border-top:none;border-right:none;border-left:none;border-bottom:1px solid;border-color:", ";width:100%;height:32px;&:placeholder-shown{padding-top:1px;padding-bottom:1px;}&:not(:placeholder-shown),&:focus{padding-top:25px;padding-bottom:25px;}}.btn-success{background:#ececec;}textarea{background-color:", ";width:100%;height:200px;border-color:", ";margin-top:15px;border:1px solid;}button[type='submit']{margin-top:30px;@media (min-width:", "){margin-top:60px;}}.error-message{color:", ";}.user-name-item{text-indent:100%;white-space:nowrap;overflow:hidden;height:0;}@media (min-width:", "){padding:40px 115px;}@media (min-width:", "){padding:80px 258px 56px;}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.form.bgColor;
}, function (props) {
  var _props$theme2, _props$theme3;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.form.textColorLight : (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.form.textColorDark;
}, function (props) {
  var _props$theme4, _props$theme5;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.form.textColorLight : (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.form.textColorDark;
}, breakPoints.desktop, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.form.bgColor;
}, function (props) {
  var _props$theme7, _props$theme8;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.form.textColorLight : (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.form.textColorDark;
}, function (props) {
  var _props$theme9;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.form.bgColor;
}, function (props) {
  var _props$theme10, _props$theme11;
  return props && props.contactUsData && props.contactUsData.fontColor === fontColor.light ? (_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.form.textColorLight : (_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.form.textColorDark;
}, breakPoints.desktop, function (props) {
  var _props$theme12;
  return (_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : _props$theme12.form.errorColor;
}, breakPoints.tabletP, breakPoints.desktop);