import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "video", "imageDetails", "videoSetting", "styleType", "srcSetSetting", "imageOptimisationType", "isHero", "isGreaterThanPhone", "isLessThanDesktop", "mobileCardBackgroundColor", "mobileHeroVariation"];
var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import styles from './Media.style';
import Image from '../../atoms/Image';
import Video from '../../atoms/Video';
var Media = function Media(_ref) {
  var className = _ref.className,
    video = _ref.video,
    imageDetails = _ref.imageDetails,
    videoSetting = _ref.videoSetting,
    styleType = _ref.styleType,
    srcSetSetting = _ref.srcSetSetting,
    imageOptimisationType = _ref.imageOptimisationType,
    isHero = _ref.isHero,
    isGreaterThanPhone = _ref.isGreaterThanPhone,
    isLessThanDesktop = _ref.isLessThanDesktop,
    mobileCardBackgroundColor = _ref.mobileCardBackgroundColor,
    mobileHeroVariation = _ref.mobileHeroVariation,
    others = _objectWithoutProperties(_ref, _excluded);
  var imageURL = imageDetails && imageDetails.image && imageDetails.image.url;
  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (!imageURL) {
    imageURL = imageDetails && imageDetails.image && imageDetails.image.file && imageDetails.image.file.url;
  }
  var mobileImageURL = imageDetails && imageDetails.mobileImage && imageDetails.mobileImage.url;

  // in case there is no mobile image defined on Contentful
  var mobileImageFallbackURL = imageDetails && imageDetails.image && imageDetails.image.url;

  //TODO: This supports the old data structure, once all queries have been updated this should be removed
  if (imageDetails && imageDetails.mobileImage && !mobileImageURL) {
    mobileImageURL = imageDetails && imageDetails.mobileImage && imageDetails.mobileImage.file && imageDetails.mobileImage.file.url;
  }
  return video ? isHero ? __jsx(Fragment, null, __jsx("div", {
    className: className + ' video-container'
  }, __jsx(Video, _extends({
    url: video.url
  }, videoSetting, {
    isHero: isHero,
    isGreaterThanPhone: isGreaterThanPhone,
    isLessThanDesktop: isLessThanDesktop,
    mobileCardBackgroundColor: mobileCardBackgroundColor,
    mobileHeroVariation: mobileHeroVariation
  }))), __jsx(Image, {
    priority: srcSetSetting && srcSetSetting.priority,
    src: video && video.thumbnail && video.thumbnail.image && video.thumbnail.image.url || video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.file && video.thumbnail.mobileImage.file.url || video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.url || video && video.thumbnail && video.thumbnail.fields && video.thumbnail.fields.image && video.thumbnail.fields.image.fields.file.url,
    mobileImageUrl: video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.file && video.thumbnail.mobileImage.file.url || video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.url || video && video.thumbnail && video.thumbnail.image && video.thumbnail.image.url || video && video.thumbnail && video.thumbnail.fields && video.thumbnail.fields.image && video.thumbnail.fields.image.fields.file.url,
    isHero: isHero,
    alt: video.thumbnail.altText || imageDetails && imageDetails.altText,
    styleType: styleType,
    className: className + ' mobile-image',
    srcSetSettingImg: srcSetSetting,
    imageOptimisationType: imageOptimisationType,
    isLessThanDesktop: isLessThanDesktop,
    isGreaterThanPhone: isGreaterThanPhone,
    mobileCardBackgroundColor: mobileCardBackgroundColor
  })) : __jsx(Fragment, null, __jsx("div", {
    className: className + ' video-container'
  }, __jsx(Video, _extends({
    url: video.url
  }, videoSetting, {
    isHero: isHero,
    isGreaterThanPhone: isGreaterThanPhone,
    src: video && video && video.thumbnail && video.thumbnail.image && video.thumbnail.image.url || video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.file && video.thumbnail.mobileImage.file.url || video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.url,
    mobileImageUrl: video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.file && video.thumbnail.mobileImage.file.url || video && video.thumbnail && video.thumbnail.mobileImage && video.thumbnail.mobileImage.url || video && video.thumbnail && video.thumbnail.image && video.thumbnail.image.url,
    alt: video.thumbnail.altText || imageDetails && imageDetails.altText,
    title: video.thumbnail.title || video.thumbnail.altText || imageDetails && imageDetails.image && (imageDetails.title || imageDetails.altText),
    styleType: styleType,
    imgClassName: className + ' mobile-image',
    srcSetSettingImg: srcSetSetting,
    imageOptimisationType: imageOptimisationType,
    mobileCardBackgroundColor: mobileCardBackgroundColor
  })))) : __jsx("div", {
    className: className
  }, __jsx(Image, {
    priority: srcSetSetting && srcSetSetting.priority,
    src: imageURL,
    alt: imageDetails && imageDetails.altText,
    styleType: styleType,
    mobileImageUrl: mobileImageURL || mobileImageFallbackURL,
    srcSetSettingImg: srcSetSetting,
    title: imageDetails && imageDetails.image && (imageDetails.title || imageDetails.altText),
    imageOptimisationType: imageOptimisationType,
    isHero: isHero,
    isLessThanDesktop: isLessThanDesktop,
    isGreaterThanPhone: isGreaterThanPhone,
    mobileCardBackgroundColor: mobileCardBackgroundColor
  }));
};
Media.defaultProps = {
  className: '',
  styleType: '',
  video: null,
  imageOptimisationType: ''
};
export default styled(Media).withConfig({
  componentId: "sc-dbhlap-0"
})(["", ";"], styles);
export { Media as MediaVanilla };