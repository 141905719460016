var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './DocumentsAndForms.style';
import RichText from '../../atoms/RichText/RichText';
import Heading from '../../atoms/Heading';
import Anchor from '../../atoms/Anchor';
import analyticsConstants from '../../../constants/analyticsConstants';
import FileIcon1 from '../../../static/images/icons/FileIcon1';
import FileIcon2 from '../../../static/images/icons/FileIcon2';
import FileIcon3 from '../../../static/images/icons/FileIcon3';
import FileIcon4 from '../../../static/images/icons/FileIcon4';
var DocumentsAndForms = function DocumentsAndForms(_ref) {
  var documentFormsData = _ref.documentFormsData,
    className = _ref.className;
  var headline = documentFormsData.headline,
    subheadline = documentFormsData.subheadline,
    textBlock = documentFormsData.textBlock,
    rightColumnHeader = documentFormsData.rightColumnHeader,
    downloadsCollection = documentFormsData.downloadsCollection;
  var downloadIconList = [FileIcon1, FileIcon2, FileIcon3, FileIcon4];
  var richText = textBlock && textBlock.json;
  var downloads = downloadsCollection && downloadsCollection.items ? downloadsCollection.items : [];
  return __jsx("div", {
    className: className,
    id: "download-form"
  }, __jsx("div", {
    className: "container"
  }, __jsx(Heading, {
    HeadingType: "h1",
    className: "document-heading"
  }, headline), __jsx(Heading, {
    HeadingType: "h5",
    className: "document-sub-heading"
  }, subheadline), __jsx("div", {
    className: "document-container"
  }, textBlock && __jsx(RichText, {
    ContainerElem: "div",
    jsonContent: richText,
    className: "download-description"
  }), __jsx("div", {
    className: "download-section"
  }, __jsx(Heading, {
    HeadingType: "h4",
    className: "download-heading"
  }, rightColumnHeader), downloads && downloads.map(function (item, index) {
    var DownloadIcon = downloadIconList[index % 4];
    return __jsx(Anchor, {
      title: item.label,
      key: index,
      to: item.url,
      hyperlinkType: item.hyperlinkType,
      styleType: "",
      className: "download-list",
      ctaBehavior: item.behavior,
      "data-analytics-type": analyticsConstants.DocumentsAndForms.analyticsType.DOWNLOAD_CTA_TYPE,
      "data-analytics-value": item && item.dataAnalyticsValue ? item.dataAnalyticsValue : item.label,
      "data-analytics-variation": ""
    }, __jsx("div", {
      className: "download-item"
    }, __jsx(DownloadIcon, {
      className: "download-icon"
    })), __jsx(Heading, {
      HeadingType: "h4",
      className: "download-label"
    }, item.label));
  })))));
};
DocumentsAndForms.defaultProps = {
  className: ''
};
export default styled(DocumentsAndForms).withConfig({
  componentId: "sc-1kocj1o-0"
})(["", ";"], styles);