import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { getBackgroundStyle, isBgColorStyleLight, setNonCtaAnchorStyling, replacePrimaryAnchorWithLightPrimaryAnchor, replaceSecondaryAnchorWithAltSecondaryAnchor } from '../../../utils/utilityFunction';
import fontColor from '../../../constants/globalConstants';
import textures from '../../../themes/textures';
import { imageRenditions } from './config';
var backgroundStyles = '';
var styles = css(["margin:0;text-align:center;", ";p + ul,p + ol{margin-top:0;}h2,h3,h4,ul,ol,p,.tile-heading,.three-tile-top-heading,.three-tile-top-description,.tile-subheading,.text-para{color:", ";a:not(.sg-btn-cpnt){", "}}.three-tile-top-heading{text-align:center;display:block;margin:0 auto 20px;}.three-tile-top-description{margin:0 auto 30px;text-align:center;ul{li{display:block;p{display:list-item;list-style-type:'\u2022  ';list-style-position:inside;}}}ol{li{display:block;p{display:list-item;list-style-position:inside;}}}@media (min-width:", "){margin:0 auto 30px;}@media (min-width:", "){margin-bottom:60px;}}.three-tile-top-cta{margin:0 0 40px;}.slick-slider{margin-top:0;@media (min-width:", "){margin-top:30px;}}.tile-image,.tile-heading,.tile-subheading,.tile-para{margin:0 0 20px;}.tile-image{overflow:hidden;", " height:", "px;@media (min-width:", "){height:", "px;}@media (min-width:", "){height:", "px;}@media (min-width:", "){height:", "px;}}.three-tile{width:280px;@media (min-width:", "){width:280px;}@media (min-width:", "){margin-right:20px;&:last-child{margin-right:0;}}@media (min-width:", "){width:308px;}@media (min-width:", "){width:338px;}@media (min-width:", "){width:396px;}}.variation-b-link{a:not(.sg-btn-cpnt){", "}.sg-btn-cpnt.secondary-anchor{", " ", "}@media (max-width:", "){", ";}}.variation-a-link{&.tile-link a:not(.sg-btn-cpnt){", "}}.variation-c-link{&.tile-link a:not(.sg-btn-cpnt){color:", ";&:hover{text-decoration:underline;}}}.tile-para{p{margin:0;}}.tile-wrapper{padding:40px 0 60px;& > .variation-b-link > .sg-btn-cpnt.primary-anchor{", "}@media (min-width:", "){margin:0;padding:80px 20px 60px;}@media (min-width:", "){padding:80px 0 60px;", "}}"], function (props) {
  if (props.threeTileData) {
    var _props$theme, _props$theme2;
    var overrideBackground = props.threeTileData.overrideBackground;
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    var backgroundImageURLs = overrideBackground && overrideBackground.imageBackground ? {
      desktopImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url,
      mobileImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url
    } : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, overrideBackground && overrideBackground.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.threeTile.bgColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, function (props) {
  var _props$threeTileData, _props$threeTileData2, _props$threeTileData3;
  if ((props === null || props === void 0 ? void 0 : (_props$threeTileData = props.threeTileData) === null || _props$threeTileData === void 0 ? void 0 : _props$threeTileData.fontColor) === null) {
    var _props$theme3, _props$theme4;
    return isBgColorStyleLight(backgroundStyles) ? (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.threeTile.textColorDark : (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.threeTile.textColorLight;
  }
  if ((props === null || props === void 0 ? void 0 : (_props$threeTileData2 = props.threeTileData) === null || _props$threeTileData2 === void 0 ? void 0 : _props$threeTileData2.fontColor) === fontColor.light) {
    var _props$theme5;
    return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.threeTile.textColorLight;
  } else if ((props === null || props === void 0 ? void 0 : (_props$threeTileData3 = props.threeTileData) === null || _props$threeTileData3 === void 0 ? void 0 : _props$threeTileData3.fontColor) === fontColor.dark) {
    var _props$theme6;
    return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.threeTile.textColorDark;
  }
}, function (props) {
  var _props$threeTileData4, _props$threeTileData5, _props$theme7;
  return (props === null || props === void 0 ? void 0 : (_props$threeTileData4 = props.threeTileData) === null || _props$threeTileData4 === void 0 ? void 0 : _props$threeTileData4.fontColor) === fontColor.light || ((_props$threeTileData5 = props.threeTileData) === null || _props$threeTileData5 === void 0 ? void 0 : _props$threeTileData5.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.threeTile) : '';
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.tabletP, function (props) {
  var _props$theme8, _props$theme9;
  return (_props$theme8 = props.theme) !== null && _props$theme8 !== void 0 && _props$theme8.threeTile.cardRadius ? "border-radius: ".concat((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.threeTile.cardRadius, ";") : '';
}, imageRenditions.mobile.h, breakPoints.tabletP, imageRenditions.tabletP.h, breakPoints.desktop, imageRenditions.tabletL.h, breakPoints.desktopM, imageRenditions.desktop.h, breakPoints.tabletP, breakPoints.tabletL, breakPoints.desktop, breakPoints.desktopM, breakPoints.desktopL, function (props) {
  var _props$theme10, _props$threeTileData6, _props$threeTileData7, _props$theme11, _props$theme12, _props$theme13;
  return (_props$theme10 = props.theme) !== null && _props$theme10 !== void 0 && _props$theme10.threeTile.btnBgColor ? (props === null || props === void 0 ? void 0 : (_props$threeTileData6 = props.threeTileData) === null || _props$threeTileData6 === void 0 ? void 0 : _props$threeTileData6.fontColor) === fontColor.light || ((_props$threeTileData7 = props.threeTileData) === null || _props$threeTileData7 === void 0 ? void 0 : _props$threeTileData7.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.threeTile) : "background-color: ".concat((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : _props$theme12.threeTile.btnBgColor, ";\n            &:hover {\n              background-color: ").concat((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.threeTile.btnHoverColor, ";\n            }") : "";
}, function (props) {
  var _props$theme14, _props$threeTileData8, _props$threeTileData9, _props$threeTileData10, _props$threeTileData11, _props$theme15;
  return (_props$theme14 = props.theme) !== null && _props$theme14 !== void 0 && _props$theme14.button.hasAlternateDarkSecondaryAnchor && ((_props$threeTileData8 = props.threeTileData) === null || _props$threeTileData8 === void 0 ? void 0 : _props$threeTileData8.fontColor) !== fontColor.light && (((_props$threeTileData9 = props.threeTileData) === null || _props$threeTileData9 === void 0 ? void 0 : _props$threeTileData9.fontColor) === fontColor.dark || (((_props$threeTileData10 = props.threeTileData) === null || _props$threeTileData10 === void 0 ? void 0 : _props$threeTileData10.fontColor) === null || ((_props$threeTileData11 = props.threeTileData) === null || _props$threeTileData11 === void 0 ? void 0 : _props$threeTileData11.fontColor) === undefined) && isBgColorStyleLight(backgroundStyles)) ? replaceSecondaryAnchorWithAltSecondaryAnchor((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : _props$theme15.button) : '';
}, function (props) {
  var _props$theme16, _props$threeTileData12, _props$threeTileData13, _props$theme17;
  return (_props$theme16 = props.theme) !== null && _props$theme16 !== void 0 && _props$theme16.button.hasAlternateLightSecondaryAnchor && ((props === null || props === void 0 ? void 0 : (_props$threeTileData12 = props.threeTileData) === null || _props$threeTileData12 === void 0 ? void 0 : _props$threeTileData12.fontColor) === fontColor.light || ((_props$threeTileData13 = props.threeTileData) === null || _props$threeTileData13 === void 0 ? void 0 : _props$threeTileData13.fontColor) === null && !isBgColorStyleLight(backgroundStyles)) ? replaceSecondaryAnchorWithAltSecondaryAnchor((_props$theme17 = props.theme) === null || _props$theme17 === void 0 ? void 0 : _props$theme17.button, false, true) : '';
}, breakPoints.mobile, function (props) {
  var _props$theme18;
  return (_props$theme18 = props.theme) !== null && _props$theme18 !== void 0 && _props$theme18.isCorporate ? "text-align: center" : "";
}, function (props) {
  var _props$threeTileData14, _props$threeTileData15, _props$theme19, _props$theme20;
  return (props === null || props === void 0 ? void 0 : (_props$threeTileData14 = props.threeTileData) === null || _props$threeTileData14 === void 0 ? void 0 : _props$threeTileData14.fontColor) === fontColor.light || ((_props$threeTileData15 = props.threeTileData) === null || _props$threeTileData15 === void 0 ? void 0 : _props$threeTileData15.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme19 = props.theme) === null || _props$theme19 === void 0 ? void 0 : _props$theme19.threeTile) : "color: ".concat((_props$theme20 = props.theme) === null || _props$theme20 === void 0 ? void 0 : _props$theme20.threeTile.ctaColor, ";\n      &:hover {\n        text-decoration: underline;\n      }");
}, function (props) {
  var _props$theme21;
  return (_props$theme21 = props.theme) === null || _props$theme21 === void 0 ? void 0 : _props$theme21.threeTile.ctaColor;
}, function (props) {
  var _props$theme22, _props$threeTileData16, _props$theme23, _props$threeTileData17, _props$theme24;
  return (_props$theme22 = props.theme) !== null && _props$theme22 !== void 0 && _props$theme22.button.hasAlternatePrimaryAnchor && ((props === null || props === void 0 ? void 0 : (_props$threeTileData16 = props.threeTileData) === null || _props$threeTileData16 === void 0 ? void 0 : _props$threeTileData16.fontColor) === fontColor.light && (_props$theme23 = props.theme) !== null && _props$theme23 !== void 0 && _props$theme23.button.linkColorLightPrimary || ((_props$threeTileData17 = props.threeTileData) === null || _props$threeTileData17 === void 0 ? void 0 : _props$threeTileData17.fontColor) === null && !isBgColorStyleLight(backgroundStyles)) ? replacePrimaryAnchorWithLightPrimaryAnchor((_props$theme24 = props.theme) === null || _props$theme24 === void 0 ? void 0 : _props$theme24.button) : "";
}, breakPoints.tabletL, breakPoints.desktop, function (props) {
  var _props$theme25, _props$theme26;
  return (_props$theme25 = props.theme) !== null && _props$theme25 !== void 0 && _props$theme25.threeTile.tileWrapperPaddingBottom ? "padding-bottom: ".concat((_props$theme26 = props.theme) === null || _props$theme26 === void 0 ? void 0 : _props$theme26.threeTile.tileWrapperPaddingBottom) : "";
});
export default styles;