var __jsx = React.createElement;
import React, { Fragment, useEffect, useState, useContext } from 'react';
import Layout from '../../../layouts';
import HeroCarousel from '../HeroCarousel';
import FiftyFifty from '../FiftyFifty';
import FiveCard from '../FiveCard';
import LocationInfo from '../LocationInfo';
import ThreeTile from '../ThreeTile';
import Reviews from '../Reviews';
import PromoBanner from '../PromoBanner';
import Accordion from '../../molecules/Accordion';
import MiniGallery from '../MiniGallery';
import ImageSlider from '../ImageSlider';
import DocumentsAndForms from '../DocumentsAndForms';
import ThreeColumnText from '../ThreeColumnText';
import Mailchimp from '../../atoms/Mailchimp';
import SecondaryPageHero from '../SecondaryPageHero';
import HeaderMultiLocation from '../HeaderMultiLocation';
import FormKeep from '../../atoms/FormKeep';
import RichText from '../../atoms/RichText';
import FiveCardNva from '../FiveCardNva';
import Table from '../../atoms/Table';
import TableEditor from '../../atoms/TableEditor';
import Spacer from '../../atoms/Spacer';
import BlogArticleList from '../BlogArticleList';
import ServicesBlade from '../ServicesBlade';
import StaffBlade from '../StaffBlade';
import PencilBanner from '../PencilBanner/PencilBanner';
import ScrollTrigger from 'react-scroll-trigger';
import { FORM_SUBMISSION_THANKYOU } from '../../../constants/labels/en';
import { isRenderForm } from '../../../utils/formUtility';
import { getScrollbarWidth, pencilUrlParseSitePage } from '../../../utils/utilityFunction';
import ContactUsForm from '../ContactUsForm';
import dynamic from 'next/dynamic';
import { Context } from '../../../app/store';

/**
  Screen component
*/

var ScreenComponent = function ScreenComponent(_ref) {
  var _combinedData$one, _collection$sitePages, _collection$sitePages2, _siteCollectionData$s, _siteCollectionData$s2, _messageContent$, _messageContent$2, _messageContent$2$ite, _filteredData$, _filteredData$2, _filteredData$2$items, _sitePagesCollectionD, _sitePagesCollectionD2, _sitePageData$sitePag, _sitePageData$sitePag2;
  var siteCollectionData = _ref.siteCollectionData,
    path = _ref.path,
    combinedData = _ref.combinedData,
    sitePagesCollectionData = _ref.sitePagesCollectionData,
    reviewData = _ref.reviewData;
  var _useState = useState(15),
    scrollbarWidth = _useState[0],
    setScrollbarWidth = _useState[1];
  var _useState2 = useState(null),
    messageContent = _useState2[0],
    setMessageContent = _useState2[1];
  var _useContext = useContext(Context),
    yextContactInformationData = _useContext.yextInfo;
  var slugUrl = '/' + path;
  if (slugUrl === '//undefined') {
    slugUrl = '/';
  }
  var collection = combinedData === null || combinedData === void 0 ? void 0 : (_combinedData$one = combinedData.one) === null || _combinedData$one === void 0 ? void 0 : _combinedData$one.items[0];
  var filteredData = collection === null || collection === void 0 ? void 0 : (_collection$sitePages = collection.sitePagesCollection) === null || _collection$sitePages === void 0 ? void 0 : (_collection$sitePages2 = _collection$sitePages.items) === null || _collection$sitePages2 === void 0 ? void 0 : _collection$sitePages2.filter(function (item) {
    var updatedUrl = item.url.charAt(0) !== '/' ? '/' + item.url : item.url;
    return updatedUrl === slugUrl;
  });
  var handleMessageReceived = function handleMessageReceived(event) {
    var whitelist = ['https://easyeditor.azureedge.net', 'https://easyeditoruat.azureedge.net', 'https://easyeditorprd.azureedge.net', 'https://easyeditor.nva.com', 'http://localhost:8910'];
    if (!whitelist.includes(event.origin)) {
      return;
    }
    if (typeof event.data === 'string') {
      var objMsg = JSON.parse(event.data);
      if (objMsg.type === 'ScreenSizeChanged') {
        setMessageContent([objMsg.content]);
      }
    }
  };
  useEffect(function () {
    window.addEventListener('message', handleMessageReceived);
    return function () {
      window.removeEventListener('message', handleMessageReceived);
    };
  }, []);
  var siteData = (siteCollectionData === null || siteCollectionData === void 0 ? void 0 : siteCollectionData.siteCollection) && (siteCollectionData === null || siteCollectionData === void 0 ? void 0 : (_siteCollectionData$s = siteCollectionData.siteCollection) === null || _siteCollectionData$s === void 0 ? void 0 : _siteCollectionData$s.items.length) && (siteCollectionData === null || siteCollectionData === void 0 ? void 0 : (_siteCollectionData$s2 = siteCollectionData.siteCollection) === null || _siteCollectionData$s2 === void 0 ? void 0 : _siteCollectionData$s2.items[0]) || {};

  // to keep the order of the blades as coming from Contentful
  // map through first response, if "name" exist, return
  // if not, map through the second, if the same __typname exist, it means it is present in the first loop, return
  // it's ugly and probably unreliable  but works, will keep testing

  var one = messageContent ? messageContent && messageContent.length && ((_messageContent$ = messageContent[0]) === null || _messageContent$ === void 0 ? void 0 : _messageContent$.itemsCollection) && ((_messageContent$2 = messageContent[0]) === null || _messageContent$2 === void 0 ? void 0 : (_messageContent$2$ite = _messageContent$2.itemsCollection) === null || _messageContent$2$ite === void 0 ? void 0 : _messageContent$2$ite.items) : filteredData && filteredData.length && ((_filteredData$ = filteredData[0]) === null || _filteredData$ === void 0 ? void 0 : _filteredData$.itemsCollection) && ((_filteredData$2 = filteredData[0]) === null || _filteredData$2 === void 0 ? void 0 : (_filteredData$2$items = _filteredData$2.itemsCollection) === null || _filteredData$2$items === void 0 ? void 0 : _filteredData$2$items.items) || {};
  var mapResToOrderedBlades = one;
  // one has site meta and "fixed blades" - pencil and hero - this may change
  var DynamicLayoutPage = messageContent ? messageContent : filteredData.length > 0 && filteredData[0] || {};
  var sitePageData = (sitePagesCollectionData === null || sitePagesCollectionData === void 0 ? void 0 : sitePagesCollectionData.siteCollection) && (sitePagesCollectionData === null || sitePagesCollectionData === void 0 ? void 0 : (_sitePagesCollectionD = sitePagesCollectionData.siteCollection) === null || _sitePagesCollectionD === void 0 ? void 0 : _sitePagesCollectionD.items.length) && (sitePagesCollectionData === null || sitePagesCollectionData === void 0 ? void 0 : (_sitePagesCollectionD2 = sitePagesCollectionData.siteCollection) === null || _sitePagesCollectionD2 === void 0 ? void 0 : _sitePagesCollectionD2.items[0]) || {};
  var filteredextensiveComponentsData = sitePageData === null || sitePageData === void 0 ? void 0 : (_sitePageData$sitePag = sitePageData.sitePagesCollection) === null || _sitePageData$sitePag === void 0 ? void 0 : (_sitePageData$sitePag2 = _sitePageData$sitePag.items) === null || _sitePageData$sitePag2 === void 0 ? void 0 : _sitePageData$sitePag2.reduce(function (filtered, item) {
    var updatedUrl = item.url.charAt(0) !== '/' ? '/' + item.url : item.url;
    if (updatedUrl === slugUrl) {
      filtered.push(item);
    }
    return filtered;
  }, []);
  var extensiveComponents = filteredextensiveComponentsData && filteredextensiveComponentsData.length && filteredextensiveComponentsData[0] || {};
  useEffect(function () {
    var scrollbarW = getScrollbarWidth();
    setScrollbarWidth(scrollbarW);
  }, []);
  if (combinedData) {
    var _combinedData$alertCo, _combinedData$alertCo2;
    var pageTitle = DynamicLayoutPage.titleTag;
    var removeHeader = DynamicLayoutPage.removeHeader;
    var removeFooter = DynamicLayoutPage.removeFooter;
    var pageDescription = DynamicLayoutPage.metaDescription;
    var doNotCrawl = DynamicLayoutPage.doNotCrawl || null;
    var globalHeaderData = siteData && siteData.header || {};
    var globalFooterData = siteData && siteData.footer || {};
    var contentfulAlert = (combinedData === null || combinedData === void 0 ? void 0 : combinedData.alertCollection) && (combinedData === null || combinedData === void 0 ? void 0 : (_combinedData$alertCo = combinedData.alertCollection) === null || _combinedData$alertCo === void 0 ? void 0 : _combinedData$alertCo.items.length) && (combinedData === null || combinedData === void 0 ? void 0 : (_combinedData$alertCo2 = combinedData.alertCollection) === null || _combinedData$alertCo2 === void 0 ? void 0 : _combinedData$alertCo2.items[0]) || {};
    var _pagePath = {
        pagePath: {}
      },
      pagePath = _pagePath.pagePath;
    var dynamicTemplatePagePencilBanner = extensiveComponents && extensiveComponents.pencilBanner;
    return __jsx(Layout, {
      globalHeaderData: globalHeaderData,
      globalFooterData: globalFooterData,
      contentfulAlertData: contentfulAlert,
      yextContactInformationData: yextContactInformationData,
      siteData: siteData,
      pageTitle: pageTitle,
      pageDescription: pageDescription,
      pagePath: path,
      removeHeader: removeHeader,
      removeFooter: removeFooter,
      doNotCrawl: doNotCrawl
    }, __jsx(Fragment, null, dynamicTemplatePagePencilBanner && pencilUrlParseSitePage(siteData.pencilBanner, path) && __jsx(PencilBanner, {
      bannerData: dynamicTemplatePagePencilBanner,
      className: "banner"
    }), extensiveComponents && extensiveComponents.hero && __jsx(HeroCarousel, {
      scrollbarWidth: scrollbarWidth,
      heroCarouselData: extensiveComponents.hero,
      shouldPreloadImage: true
    }), mapResToOrderedBlades && Object.keys(mapResToOrderedBlades).length > 0 && mapResToOrderedBlades.map(function (component, index) {
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'PencilBanner' && component.name) {
        return __jsx(PencilBanner, {
          key: index,
          bannerData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'Story' && component.name) {
        return __jsx(FiveCardNva, {
          scrollbarWidth: scrollbarWidth,
          key: index,
          fiveCardNvaData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'SecondaryHero' && component.name) {
        return __jsx(SecondaryPageHero, {
          key: index,
          heroData: component,
          className: "secondary-hero",
          scrollbarWidth: scrollbarWidth,
          shouldPreloadImage: index === 0 && !extensiveComponents.hero
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'LocatorMap' && component.name) {
        return __jsx(HeaderMultiLocation, {
          key: index,
          isBlade: true,
          multiLocationMenuContainers: component,
          themeName: siteData.theme
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'HeroCarousel' && component.name) {
        return __jsx(HeroCarousel, {
          scrollbarWidth: scrollbarWidth,
          key: index,
          heroCarouselData: component,
          shouldPreloadImage: index === 0 && !extensiveComponents.hero
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'PromoBanner' && component.name) {
        return __jsx(ScrollTrigger, {
          key: index
        }, __jsx(PromoBanner, {
          key: index,
          promoData: component
        }));
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'Spacer' && component.space) {
        return __jsx(Spacer, {
          space: component.space,
          background: component.background,
          key: index
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'RichTextBlob' && component.name) {
        var topPadding = component.topPadding,
          bottomPadding = component.bottomPadding;
        var className = "rich-text-blob container";
        // top padding from contentful
        className += topPadding === null || topPadding === 'Default' || topPadding === undefined ? '' : " padding-top-override-".concat(topPadding === null || topPadding === void 0 ? void 0 : topPadding.toLowerCase());
        // bottom padding from contentful
        className += bottomPadding === null || bottomPadding === 'Default' || bottomPadding === undefined ? '' : " padding-bottom-override-".concat(bottomPadding === null || bottomPadding === void 0 ? void 0 : bottomPadding.toLowerCase());
        var anchorLink = (component === null || component === void 0 ? void 0 : component.anchorLink) || '';
        return __jsx("div", {
          className: className,
          key: index,
          style: {
            padding: pagePath == '/terms-conditions' || pagePath == '/thank-you' || pagePath == '/privacy-policy' ? '80px 25px' : '80px 0px'
          },
          id: anchorLink
        }, __jsx("div", {
          className: "block block0 col-xs-12"
        }, __jsx(RichText, {
          jsonContent: component.body.json
        })));
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'CategoryCardsBlade' && component.name) {
        return __jsx(FiveCard, {
          scrollbarWidth: scrollbarWidth,
          key: index,
          fiveCardData: component,
          themeName: siteData.theme
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'LocationInformationBlade' && component.name) {
        return __jsx(LocationInfo, {
          key: index,
          locationInfoData: component,
          yextContactInfo: yextContactInformationData
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'AccordionBlade' && component.name) {
        return __jsx(Accordion, {
          key: index,
          accordionData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'TileCategoryCardsBlade' && component.name) {
        return __jsx(ThreeTile, {
          key: index,
          threeTileData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'ReviewsBlade' && component.name) {
        var showBirdApiData = false;
        if (component !== null && component !== void 0 && component.birdeyeApiBusinessId) {
          showBirdApiData = true;
        }
        return __jsx(Reviews, {
          key: index,
          reviewsData: component,
          showBirdApiData: showBirdApiData,
          birdAPIData: reviewData,
          birdeyeApiBusinessId: component.birdeyeApiBusinessId
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'Panel' && component.name) {
        return __jsx(FiftyFifty, {
          key: index,
          fiftyFiftyData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'Gallery' && component.name) {
        return __jsx(MiniGallery, {
          key: index,
          miniGalleryData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'ImageSlider' && component.name) {
        return __jsx(ImageSlider, {
          key: index,
          imageSliderData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'DocumentsAndForms' && component.name) {
        return __jsx(DocumentsAndForms, {
          documentFormsData: component,
          className: "download-form",
          key: index
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'ColumnTextBlocks' && component.name) {
        return __jsx(ThreeColumnText, {
          key: index,
          threeColumnTextData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'Mailchimp' && component.name) {
        return __jsx(Mailchimp, {
          key: index,
          mailchimpdata: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'FormkeepForm' && component.name) {
        var formData = {
          submitUrl: component.submitUrl,
          successfulSubmissionMessage: component.successfulMessage || FORM_SUBMISSION_THANKYOU,
          reCaptchaKey: siteData.reCaptchaKey,
          fontColor: component.fontColor
        };
        if (component.showNewForm == true && component.formKeepCode) {
          return __jsx(FormKeep, {
            reCaptchaKey: siteData.reCaptchaKey,
            formkeepTest: component.formKeepCode,
            bookFormData: formData
          });
        } else if (isRenderForm(formData)) {
          return __jsx(ContactUsForm, {
            contactUsData: formData
          });
        }
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'Table' && component.name) {
        var _anchorLink = component && (component === null || component === void 0 ? void 0 : component.anchorLink) || '';
        {
          /* console.log(
          `component?.tableEditor ${component?.tableEditor} ${
            component?.tableEditor && component?.tableEditor.length
          }`
          );
          console.log(
          `component?.tableContent ${component?.tableContent}`
          ); */
        }
        return __jsx(React.Fragment, null, (component === null || component === void 0 ? void 0 : component.tableEditor) && __jsx(TableEditor, {
          tables: component === null || component === void 0 ? void 0 : component.tableEditor
        }), (component === null || component === void 0 ? void 0 : component.tableContent) && (!(component !== null && component !== void 0 && component.tableEditor) || (component === null || component === void 0 ? void 0 : component.tableEditor.length) <= 0) && __jsx(Table, {
          content: component === null || component === void 0 ? void 0 : component.tableContent,
          anchorLink: _anchorLink
        }));
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'LocationsHappyHealthyPets' && component.name) {
        // import GoogleMapsHappyHealthyPets from '../GoogleMapsHappyHealthyPets';
        var GoogleMapsHappyHealthyPets = dynamic(function () {
          return import('../GoogleMapsHappyHealthyPets');
        }, {
          ssr: false,
          loadableGenerated: {
            webpack: function webpack() {
              return [require.resolveWeak('../GoogleMapsHappyHealthyPets')];
            }
          }
        });
        return __jsx(GoogleMapsHappyHealthyPets, {
          happyHealthyPetsData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'BlogBlade' && component.name) {
        var categories = component.itemsCollection.items.reduce(function (aggr, record) {
          var category = record.blogCategory.categoryName;
          if (!aggr.includes(category)) {
            aggr.push(category);
          }
          return aggr;
        }, []);
        var _anchorLink2 = (component === null || component === void 0 ? void 0 : component.anchorLink) || '';
        return __jsx(BlogArticleList, {
          key: index,
          blogArticleListData: component.itemsCollection.items,
          currentPage: "{currentPage}",
          slugifiedTitle: "{title}",
          categoryValues: categories,
          selectedValue: "",
          categoryFilter: categories,
          pageUrl: DynamicLayoutPage.url || '/blog',
          anchorLink: _anchorLink2
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'ServicesBladeBlockListing' && component.name) {
        return __jsx(ServicesBlade, {
          key: index,
          pageUrl: DynamicLayoutPage.url || '/services',
          servicesBladeData: component
        });
      }
      if ((component === null || component === void 0 ? void 0 : component.__typename) == 'StaffBlade' && component.name) {
        return __jsx(StaffBlade, {
          staffBladeData: component,
          pageUrl: DynamicLayoutPage.url || 'staff',
          key: index,
          pageType: DynamicLayoutPage.pageType || 'Staff'
        });
      }
    })));
  }
  return __jsx("p", null, " Loading ");
};
export default ScreenComponent;