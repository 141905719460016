import styled, { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var styles = css(["", ";&.video-container{display:", ";", ";@media (min-width:", "){display:block;", "}@media (min-width:", "){display:block;", "}iframe{", " border:0;width:100%;height:100%;", ";@media (max-width:", "){", ";}}}&.mobile-image{", "}"], function (props) {
  return props.styleType === 'heroCarousel' || props.styleType === 'videoSliderImage' ? "\n        &.video-container {\n            height: 0;\n        }\n    " : '';
}, function (props) {
  return props.styleType === 'videoSliderImage' ? 'block' : 'none';
}, function (props) {
  return props.styleType === 'videoSliderImage' ? "\n      margin: auto;\n      position: relative;\n      width:95vw;\n      height:54vw;\n    " : '';
}, breakPoints.desktop, function (props) {
  return props.styleType === 'videoSliderImage' ? "width:80vw;\n    height: 45vw;" : '';
}, breakPoints.desktopL, function (props) {
  return props.styleType === 'videoSliderImage' ? "width:1000px;\n            height: 562px;" : '';
}, function (props) {
  return props.styleType !== 'heroCarousel' && 'background-color: #000;';
}, function (props) {
  return props.styleType === 'videoSliderImage' ? "position: absolute;" : '';
}, breakPoints.mobile, function (props) {
  return props.styleType === 'videoSliderImage' ? "border: 0;\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      " : '';
}, function (props) {
  return props.styleType === 'videoSliderImage' ? "display: block;" : '';
});
export default styles;