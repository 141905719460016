import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["content", "className", "anchorLink"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Table.style';
import parse from 'html-react-parser';
var Table = function Table(_ref) {
  var content = _ref.content,
    className = _ref.className,
    anchorLink = _ref.anchorLink,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: className,
    id: anchorLink
  }, parse(content));
};
Table.defaultProps = {
  content: '',
  inheritedStyles: '',
  anchorLink: ''
};
export default styled(Table).withConfig({
  componentId: "sc-tqx370-0"
})(["", ";"], styles);
export { Table as TableVanilla };