var __jsx = React.createElement;
/**
 * Represents Gallery Modal Component.
 * @author Harmeet
 */import React from 'react';
import { galleryModalImageRenditions } from './GalleryModalConfig';
import Modal from '../../molecules/Modal';
import MediaTextCarousel from '../../molecules/MediaTextCarousel';
import constants from '../../../constants/globalConstants';
import analyticsConstants from '../../../constants/analyticsConstants';
var GalleryModal = function GalleryModal(_ref) {
  var galleryModalData = _ref.galleryModalData,
    _onClose = _ref.onClose;
  if (!galleryModalData) {
    return null;
  }
  var cardsArr = [];
  var cardData = {};
  var allCardData = galleryModalData.allGalleryData;
  allCardData && allCardData.map(function (card, index) {
    cardData = {
      headline: card.title,
      subHeadingText: card.attribution,
      textBlock: {
        textBlock: card.imageCaption
      },
      headingType: 'h3',
      headingTypeSubHeading: 'h4',
      video: card.fileType === 'video' ? {
        url: card.modalUrl,
        title: card.title,
        thumbnail: {
          altText: card.altText,
          title: card.title,
          mobileImage: {
            file: {
              url: card.imgSrc
            }
          }
        }
      } : null,
      backgroundImage: {
        altText: card.altText,
        title: card.title,
        image: {
          file: {
            url: card.modalUrl
          }
        },
        mobileImage: {
          file: {
            url: card.modalUrl
          }
        }
      }
    };
    cardsArr.push(cardData);
  });
  var dto = {
    variation: constants.variations.variationGallery,
    timePerSlide: 4000000,
    initialSlide: galleryModalData.selectedIndex,
    dotsState: false,
    cards: cardsArr,
    videoSettings: {
      autoplay: '0',
      controls: '0',
      loop: '1',
      fs: '0'
    },
    variationClass: constants.variations.variationGallery.toLowerCase(),
    analytics: analyticsConstants.galleryModal,
    srcSetSetting: {
      imageRenditions: galleryModalImageRenditions
    },
    styleType: 'heroCarousel',
    imageOptimisationType: 'modalParams'
  };
  return __jsx("div", {
    className: "organism GalleryModal"
  }, galleryModalData && galleryModalData.modalActive && __jsx(Modal, {
    modalActive: galleryModalData.modalActive,
    onClose: function onClose() {
      return _onClose();
    }
  }, __jsx("div", {
    className: "molecule MediaTextCarousel"
  }, __jsx(MediaTextCarousel, {
    mediaTextCarouselData: dto
  }))));
};
export default GalleryModal;