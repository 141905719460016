import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var footerStyles = css([".hero-carousel &{position:relative;}.cardNumbers{text-align:center;padding-top:10px;font-family:", ";font-size:", ";line-height:", ";letter-spacing:", ";@media (min-width:", "){padding:30px 0 10px;}}@media (max-width:", "){.card-text > div{", "}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.fontFamilyP;
}, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.fontSizeP;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.lineHeightP;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.letterSpacingP;
}, breakPoints.desktop, breakPoints.tabletL, function (props) {
  var _props$theme5, _props$theme6;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && _props$theme5.card.textAlignment ? ((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.card.textAlignment) === 'center' ? "\n        display: flex;\n        align-items: center;\n        flex-direction: column;\n        h1,\n        p {\n          text-align: center;\n        }\n          " : '' : '';
});
export default footerStyles;