import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var FormKeepStyles = css(["padding:40px 20px;background-color:", ";display:flex;flex-direction:column;form{p{width:45%;display:inline-block;margin-right:2%;margin-bottom:15px;}input[type='date']{padding:0;}}.checkin-date{width:48%;float:left;margin-right:2%;margin-bottom:20px;}[type='radio'],[type='checkbox']{display:inline-block;width:auto;height:auto;margin:10px;}label{display:block;font-weight:bold;padding-right:15px;margin-bottom:5px;}.clear{clear:both;}.formKeepField{width:45%;margin-right:4%;display:inline-block;> div{margin-bottom:40px;input{border:1px solid;&:focus{padding-top:15px;padding-bottom:15px;}}label{padding-left:5px;}.error svg{right:7px;bottom:7px;}}}.form-heading,.success-message,.error-message{text-align:center;width:100%;margin-bottom:30px;@media (min-width:", "){margin-bottom:80px;}}input{background-color:", ";border-radius:0;border:1px solid;border-color:", ";height:26px;width:100%;&:placeholder-shown{padding-top:1px;padding-bottom:1px;}&:not(:placeholder-shown),&:focus{padding-top:15px;padding-bottom:15px;}}button{display:block;position:relative;border:none;font-family:", ";background-color:", ";border-radius:", ";", " color:", ";font-size:", ";padding:15px 40px;line-height:15px;&:hover{color:", ";", " background-color:", ";", "}@media (min-width:", "){padding:15px 30px;}}.btn-success{background:#ececec;}textarea{background-color:", ";width:100%;height:200px;border-color:", ";margin-top:15px;border:1px solid;}button[type='submit']{margin-top:30px;@media (min-width:", "){margin-top:60px;}}.error-message{color:", ";}.user-name-item{text-indent:100%;white-space:nowrap;overflow:hidden;height:0;}@media (min-width:", "){padding:40px 90px;}@media (min-width:", "){padding:80px 50px 56px;}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.form.bgColor;
}, breakPoints.desktop, function (props) {
  var _props$theme2;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.form.bgColor;
}, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.form.borderColor;
}, function (props) {
  var _props$theme4;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.fontFamilyBtn;
}, function (props) {
  var _props$theme5;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.button.backgroundColorPrimary;
}, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.button.borderRadiusPrimary;
}, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) !== null && _props$theme7 !== void 0 && _props$theme7.button.borderColorPrimary ? "\n      border-width: 2px;\n      border-color: ".concat(function (props) {
    var _props$theme8;
    return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.button.borderColorPrimary;
  }, ";\n      border-style: solid;\n        ") : '';
}, function (props) {
  var _props$theme9;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.button.linkColorPrimary;
}, function (props) {
  var _props$theme10;
  return (_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.fontSizeBase;
}, function (props) {
  var _props$theme11;
  return (_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.button.linkColorPrimaryHover;
}, function (props) {
  var _props$theme12;
  return (_props$theme12 = props.theme) !== null && _props$theme12 !== void 0 && _props$theme12.button.isPrimaryGradient ? "background-image: none" : '';
}, function (props) {
  var _props$theme13;
  return (_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.button.backgroundColorHoverPrimary;
}, function (props) {
  var _props$theme14, _props$theme15;
  return (_props$theme14 = props.theme) !== null && _props$theme14 !== void 0 && _props$theme14.button.borderColorPrimaryHover ? "\n          border-color: ".concat((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : _props$theme15.button.borderColorPrimaryHover, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme16;
  return (_props$theme16 = props.theme) === null || _props$theme16 === void 0 ? void 0 : _props$theme16.form.bgColor;
}, function (props) {
  var _props$theme17;
  return (_props$theme17 = props.theme) === null || _props$theme17 === void 0 ? void 0 : _props$theme17.form.borderColor;
}, breakPoints.desktop, function (props) {
  var _props$theme18;
  return (_props$theme18 = props.theme) === null || _props$theme18 === void 0 ? void 0 : _props$theme18.form.errorColor;
}, breakPoints.tabletP, breakPoints.desktop);
export default FormKeepStyles;