import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { getImageRenditions, getImageRenditionsVariationC } from './HeroCarouselUtility';
var getSrcSetSetting = function getSrcSetSetting(heroCarouselData) {
  var mobileHeroVariation = heroCarouselData && heroCarouselData.mobileHeroVariation && heroCarouselData.mobileHeroVariation.toLowerCase() || 'split';
  var height = heroCarouselData && heroCarouselData.height && heroCarouselData.height.toLowerCase() || 'large';
  return {
    imageRenditions: heroCarouselData && heroCarouselData.variation.toLowerCase() === 'variation-c' ? getImageRenditionsVariationC(height, mobileHeroVariation) : getImageRenditions(height, mobileHeroVariation),
    quality: 72
  };
};
var styles = css(["background-color:", ";line-height:0;.video-container{pointer-events:none;&.variation-a,&.variation-b{iframe{margin-bottom:0;position:absolute;width:100%;height:calc(0.5625 * (100vw ", "));max-height:900px;z-index:0;top:50%;left:50%;transform:translate(-50%,-50%) scale(1);@media (min-width:", "){transform:translate(-50%,-50%) scale(1);max-height:calc(0.5625 * ", ");height:calc(0.5625 * ", ");}@media (min-width:", "){transform:translate(-50%,-50%) scale(1);height:calc(0.5625 * (", " ", "));max-height:calc(0.5625 * ", ");}@media (min-width:", "){transform:translate(-50%,-50%) scale(1);max-height:900px;height:calc(0.5625 * (100vw ", "));}}}&.variation-c{iframe{margin-bottom:0;position:absolute;width:calc(100vw + 2px);height:calc(0.5625 * (100vw ", "));max-height:900px;z-index:0;top:50%;left:50%;transform:translate(-50%,-50%) scale(1);@media (min-width:", "){transform:translate(-50%,-50%) scale(1);height:calc(0.5625 * ", ");}@media (min-width:", "){transform:translate(-50%,-50%) scale(1);height:calc(0.5625 * ", ");}}}}.card-header{margin-bottom:20px;}.hero-carousel{.variation-a,.variation-b{@media (min-width:", "){&.small{.slick-arrow{top:156px;}}&.medium{.slick-arrow{top:208px;}}}}}.slick-arrow{display:block !important;@media (min-width:", "){display:block !important;}@media (max-width:", ") and (min-width:", "){top:", "px;}@media (max-width:", "){top:", "px;}}.slick-dots{width:100%;padding-bottom:0;button{", " ", " @media (min-width:", "){", "}}.slick-dots-custom{", " ", " @media (min-width:", "){", "}}.slick-active{button{", " @media (min-width:", "){", "}}.slick-dots-custom{", " @media (min-width:", "){", "}}}}@media (min-width:", "){.slick-dots{padding-bottom:0;}}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.carousel.bgColor;
}, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.desktopS, breakPoints.desktop, breakPoints.desktopS, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.desktopS, breakPoints.desktopS, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.desktop, breakPoints.desktopS, breakPoints.desktop, breakPoints.desktop, breakPoints.tabletL, breakPoints.mobile, function (props) {
  return getSrcSetSetting(props.heroCarouselData).imageRenditions.tabletP.h / 2 || 250;
}, breakPoints.mobile, function (props) {
  return getSrcSetSetting(props.heroCarouselData).imageRenditions.mobile.h / 2 || 250;
}, function (props) {
  var _props$theme2, _props$theme3;
  return (_props$theme2 = props.theme) !== null && _props$theme2 !== void 0 && _props$theme2.carousel.nvaHeroPaginationBorderColor ? "border-color: ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.carousel.nvaHeroPaginationBorderColor, ";") : '';
}, function (props) {
  var _props$theme4, _props$theme5;
  return (_props$theme4 = props.theme) !== null && _props$theme4 !== void 0 && _props$theme4.carousel.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.carousel.nvaHeroPaginationInactiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme6, _props$theme7;
  return (_props$theme6 = props.theme) !== null && _props$theme6 !== void 0 && _props$theme6.carousel.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.carousel.nvaHeroPaginationInactiveColor, ";") : '';
}, function (props) {
  var _props$theme8, _props$theme9;
  return (_props$theme8 = props.theme) !== null && _props$theme8 !== void 0 && _props$theme8.carousel.nvaHeroPaginationBorderColor ? "border-color: ".concat((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.carousel.nvaHeroPaginationBorderColor, ";") : '';
}, function (props) {
  var _props$theme10, _props$theme11;
  return (_props$theme10 = props.theme) !== null && _props$theme10 !== void 0 && _props$theme10.carousel.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.carousel.nvaHeroPaginationInactiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme12, _props$theme13;
  return (_props$theme12 = props.theme) !== null && _props$theme12 !== void 0 && _props$theme12.carousel.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.carousel.nvaHeroPaginationInactiveColor, ";") : '';
}, function (props) {
  var _props$theme14, _props$theme15;
  return (_props$theme14 = props.theme) !== null && _props$theme14 !== void 0 && _props$theme14.carousel.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : _props$theme15.carousel.nvaHeroPaginationActiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme16, _props$theme17;
  return (_props$theme16 = props.theme) !== null && _props$theme16 !== void 0 && _props$theme16.carousel.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme17 = props.theme) === null || _props$theme17 === void 0 ? void 0 : _props$theme17.carousel.nvaHeroPaginationActiveColor, ";") : '';
}, function (props) {
  var _props$theme18, _props$theme19;
  return (_props$theme18 = props.theme) !== null && _props$theme18 !== void 0 && _props$theme18.carousel.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme19 = props.theme) === null || _props$theme19 === void 0 ? void 0 : _props$theme19.carousel.nvaHeroPaginationActiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme20, _props$theme21;
  return (_props$theme20 = props.theme) !== null && _props$theme20 !== void 0 && _props$theme20.carousel.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme21 = props.theme) === null || _props$theme21 === void 0 ? void 0 : _props$theme21.carousel.nvaHeroPaginationActiveColor, ";") : '';
}, breakPoints.desktop);
export default styles;