var __jsx = React.createElement;
/**
 * Represents 50-50 Component.
 * @author Harmeet
 */import React from 'react';
import FiftyFiftyCard from '../../molecules/FiftyFiftyCard';
import { imageRenditions, videoImageRenditions } from './config';
import urlSlug from 'url-slug';
var FiftyFifty = function FiftyFifty(_ref) {
  var fiftyFiftyData = _ref.fiftyFiftyData;
  if (!fiftyFiftyData) {
    return false;
  }
  var variation = fiftyFiftyData.variation,
    topPadding = fiftyFiftyData.topPadding,
    bottomPadding = fiftyFiftyData.bottomPadding,
    anchorLink = fiftyFiftyData.anchorLink;
  var idVal = anchorLink ? urlSlug(anchorLink) : '';
  var className = "fifty-fifty-card row ".concat(variation);

  // top padding from contentful
  className += topPadding === null || topPadding === 'Default' ? '' : " padding-top-override-".concat(topPadding.toLowerCase());

  // bottom padding from contentful
  className += bottomPadding === null || bottomPadding === 'Default' ? '' : " padding-bottom-override-".concat(bottomPadding.toLowerCase());
  var text = fiftyFiftyData.shortText && fiftyFiftyData.shortText.shortText ? fiftyFiftyData.shortText.shortText : fiftyFiftyData.shortText && fiftyFiftyData.shortText.json ? fiftyFiftyData.shortText.json : null;
  var bladeBackgroundImage = fiftyFiftyData.bladeBackgroundImage && fiftyFiftyData.bladeBackgroundImage.image && fiftyFiftyData.bladeBackgroundImage.image.file ? fiftyFiftyData.bladeBackgroundImage.image.file.url : fiftyFiftyData.bladeBackgroundImage && fiftyFiftyData.bladeBackgroundImage.image && fiftyFiftyData.bladeBackgroundImage.image.url;
  var dto = {
    className: className,
    ariaLabel: fiftyFiftyData.cta ? fiftyFiftyData.cta.label : null,
    fontColor: fiftyFiftyData.fontColor,
    headingText: fiftyFiftyData.headline,
    subHeadingText: fiftyFiftyData.subHeadline,
    descriptionText: text,
    backgroundTheme: "".concat(fiftyFiftyData.backgroundTheme),
    overrideBackground: fiftyFiftyData.overrideBackground,
    imageSource: "".concat(bladeBackgroundImage),
    placeholderSrc: "".concat(bladeBackgroundImage),
    media: {
      image: fiftyFiftyData.bladeBackgroundImage,
      video: fiftyFiftyData.video || ''
    },
    alt: fiftyFiftyData.headline,
    cta: fiftyFiftyData.cta ? {
      url: "".concat(fiftyFiftyData.cta.url),
      label: "".concat(fiftyFiftyData.cta.label),
      behavior: "".concat(fiftyFiftyData.cta.behavior),
      hyperlinkType: "".concat(fiftyFiftyData.cta.hyperlinkType)
    } : null,
    styleTypeAnchor: 'primary-anchor',
    styleType: 'fiftyFifty',
    classNameHeading: 'fiftyFiftyHeading',
    classNameSubHeading: 'fiftyFiftySubHeading heading_four',
    classNamePara: 'fiftyFiftyPara',
    classNameAnchor: 'fiftyFiftyAnchor  cta_btn btn',
    dataAnalytics: {
      type: 'cta-50-50',
      value: fiftyFiftyData.headline,
      variation: variation
    },
    srcSetSetting: {
      imageRenditions: fiftyFiftyData.video ? videoImageRenditions : imageRenditions
    }
  };
  return __jsx("div", {
    className: "organism fifty-fifty",
    id: idVal
  }, __jsx(FiftyFiftyCard, dto));
};
export default FiftyFifty;