import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { transparentize } from 'polished';
import { getBackgroundStyle } from '../../../utils/utilityFunction';
import textures from '../../../themes/textures';
import { imageRenditions, imageRenditionsEquine, imageRenditionsVariationB } from './config';
var backgroundStyles = '';
var styles = css(["", ";background-size:cover;.showAllTilesContainer{width:100%;justify-content:center;@media (min-width:", "){", "}}.slick_anchor{position:relative;", "}.slick-dots{bottom:-45px;}.heading_four{color:", ";}.variation-b{.slick_para{text-align:center;line-height:0;p{margin-top:10px;margin-bottom:10px;}}.imageWrapper{display:inline-block;width:", "px;overflow:hidden;height:", "px;svg g > *{fill:", ";stroke:", ";}@media (max-width:", "){width:", "px;height:", "px;}@media (max-width:", "){width:", "px;height:", "px;}@media (max-width:", "){width:", "px;height:", "px;}}.slick_anchor{box-shadow:0 0 30px 0 rgba(0,0,0,0.05);text-align:center;width:", "px;background-color:", ";@media (max-width:", "){width:", "px;}@media (max-width:", "){width:", "px;}@media (max-width:", "){width:", "px;}}.ImageLinkContainer{line-height:0;text-align:center;}.slick_header{display:inline-block;color:", ";}.slick-arrow{width:46px;height:46px;border-radius:25px;}}.five-card{", " @media (min-width:", "){", " ", " line-height:0;}}.variation-a{.slick_para{position:absolute;bottom:0;", " ", " ", ";word-break:break-word;width:", ";overflow:hidden;", " @media (min-width:", "){width:", ";}@media (min-width:", "){width:", ";}}.slick_header{padding:24px 15px;margin:0;@media (min-width:", "){padding:17px 15px;}}.ImageLinkContainer{line-height:0;text-align:center;@media (min-width:", "){", "}}.imageWrapper{", " overflow:hidden;opacity:0.9;height:", ";max-height:", ";@media (min-width:", "){height:", "px;}@media (min-width:", "){max-height:", ";height:", ";", "}@media (min-width:", "){max-height:", ";height:", ";", "}}.slick_anchor{", " &:hover{.imageWrapper{opacity:1;transition:opacity 0.5s ease-out;}.heading_four{color:", ";}.slick_para{", "}}}.slick_image{margin:0;}}.variation-b .slick_anchor:hover{background-color:", ";", " svg g > *{fill:", ";stroke:", ";}}"], function (props) {
  if (props.fiveCardData) {
    var _props$theme, _props$theme2;
    var overrideBackground = props.fiveCardData.overrideBackground;
    var backgroundImageURLs = overrideBackground && overrideBackground.imageBackground ? {
      desktopImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url,
      mobileImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url
    } : '';
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, overrideBackground && overrideBackground.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.fiveCard.bgColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, breakPoints.desktop, function (props) {
  var _props$theme3;
  return (_props$theme3 = props.theme) !== null && _props$theme3 !== void 0 && _props$theme3.fiveCard.removeSpaceBetween ? "justify-content: center;" : "justify-content: space-around;";
}, function (props) {
  var _props$theme4, _props$theme5;
  return (_props$theme4 = props.theme) !== null && _props$theme4 !== void 0 && _props$theme4.fiveCard.cardRadius ? "border-radius: ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.fiveCard.cardRadius, ";\n    overflow: hidden;") : '';
}, function (props) {
  var _props$theme6;
  return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.fiveCard.textColor;
}, imageRenditionsVariationB.desktopL.w, imageRenditionsVariationB.desktopL.h, function (props) {
  var _props$theme7;
  return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.fiveCard.iconColor;
}, function (props) {
  var _props$theme8;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.fiveCard.iconColor;
}, breakPoints.desktopM, imageRenditionsVariationB.desktop.w, imageRenditionsVariationB.desktop.h, breakPoints.desktop, imageRenditionsVariationB.desktop.w, imageRenditionsVariationB.desktop.h, breakPoints.tabletL, imageRenditionsVariationB.tabletL.w, imageRenditionsVariationB.tabletL.h, imageRenditionsVariationB.desktopL.w, function (props) {
  var _props$theme9;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.fiveCard.cardBgColorB;
}, breakPoints.desktopM, imageRenditionsVariationB.desktop.w, breakPoints.desktop, imageRenditionsVariationB.desktop.w, breakPoints.tabletL, imageRenditionsVariationB.tabletL.w, function (props) {
  var _props$theme10;
  return (_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.fiveCard.cardCTAColorB;
}, function (props) {
  var _props$theme11, _props$theme12;
  return (_props$theme11 = props.theme) !== null && _props$theme11 !== void 0 && _props$theme11.fiveCard.replaceContainerPaddingWithMargin ? "margin: ".concat(((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : _props$theme12.fiveCard.containerMobileMargin) || '40px 0 80px', ";") : "padding: 40px 0 80px;";
}, breakPoints.desktop, function (props) {
  var _props$theme13;
  return (_props$theme13 = props.theme) !== null && _props$theme13 !== void 0 && _props$theme13.fiveCard.containerWidthFull ? "min-width: 100%;" : '';
}, function (props) {
  var _props$theme14, _props$theme15;
  return (_props$theme14 = props.theme) !== null && _props$theme14 !== void 0 && _props$theme14.fiveCard.replaceContainerPaddingWithMargin ? "margin: ".concat(((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : _props$theme15.fiveCard.containerDesktopMargin) || '80px 0', ";") : "padding: 80px 0;";
}, function (props) {
  var _props$theme16;
  return (_props$theme16 = props.theme) !== null && _props$theme16 !== void 0 && _props$theme16.fiveCard.containerWidthFull ? "height: 20%;\n    transition: 0.35s cubic-bezier(0.17, 0.67, 0.5, 1.03);" : '';
}, function (props) {
  var _props$theme17;
  return (_props$theme17 = props.theme) !== null && _props$theme17 !== void 0 && _props$theme17.fiveCard.centerAnchorText ? "display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;" : '';
}, function (props) {
  var _props$theme18, _props$theme19, _props$theme20, _props$theme21;
  return (_props$theme18 = props.theme) !== null && _props$theme18 !== void 0 && _props$theme18.fiveCard.textBgOpacity ? "background-color:  ".concat(transparentize((_props$theme19 = props.theme) === null || _props$theme19 === void 0 ? void 0 : _props$theme19.fiveCard.textBgOpacity, (_props$theme20 = props.theme) === null || _props$theme20 === void 0 ? void 0 : _props$theme20.fiveCard.bgColorPara), ";") : "background-color: ".concat((_props$theme21 = props.theme) === null || _props$theme21 === void 0 ? void 0 : _props$theme21.fiveCard.bgColorPara, ";");
}, function (props) {
  var _props$theme22;
  return (_props$theme22 = props.theme) !== null && _props$theme22 !== void 0 && _props$theme22.fiveCard.textBGWidthFull ? '100%' : '260px';
}, function (props) {
  var _props$theme23;
  return (_props$theme23 = props.theme) !== null && _props$theme23 !== void 0 && _props$theme23.fiveCard.removeAnchorTextArrow ? ".arrow:after {\n        content: '';\n      }" : '';
}, breakPoints.desktop, function (props) {
  var _props$theme24;
  return (_props$theme24 = props.theme) !== null && _props$theme24 !== void 0 && _props$theme24.fiveCard.textBGWidthFull ? '100%' : '162px';
}, breakPoints.desktopM, function (props) {
  var _props$theme25;
  return (_props$theme25 = props.theme) !== null && _props$theme25 !== void 0 && _props$theme25.fiveCard.textBGWidthFull ? '100%' : '178px';
}, breakPoints.desktop, breakPoints.desktop, function (props) {
  var _props$theme26;
  return (_props$theme26 = props.theme) !== null && _props$theme26 !== void 0 && _props$theme26.fiveCard.containerWidthFull ? "flex-basis: 20%;" : '';
}, function (props) {
  var _props$theme27;
  return (_props$theme27 = props.theme) !== null && _props$theme27 !== void 0 && _props$theme27.fiveCard.containerWidthFull ? "width: 100%;" : "width: 280px;";
}, function (props) {
  var _props$theme28, _props$theme28$fiveCa;
  return (_props$theme28 = props.theme) !== null && _props$theme28 !== void 0 && (_props$theme28$fiveCa = _props$theme28.fiveCard) !== null && _props$theme28$fiveCa !== void 0 && _props$theme28$fiveCa.useEquineRenditions ? "calc(".concat((imageRenditionsEquine.mobile.h / imageRenditionsEquine.mobile.w).toFixed(4), " * (100vw ").concat(props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '', "))") : "".concat(imageRenditions.mobile.h, "px");
}, function (props) {
  var _props$theme29;
  return (_props$theme29 = props.theme) !== null && _props$theme29 !== void 0 && _props$theme29.fiveCard.useEquineRenditions ? "".concat(imageRenditionsEquine.mobile.h, "px") : '';
}, breakPoints.tabletP, function (props) {
  var _props$theme30, _props$theme30$fiveCa;
  return (_props$theme30 = props.theme) !== null && _props$theme30 !== void 0 && (_props$theme30$fiveCa = _props$theme30.fiveCard) !== null && _props$theme30$fiveCa !== void 0 && _props$theme30$fiveCa.useEquineRenditions ? imageRenditionsEquine.tabletP.h : imageRenditions.tabletP.h;
}, breakPoints.desktop, function (props) {
  var _props$theme31;
  return (_props$theme31 = props.theme) !== null && _props$theme31 !== void 0 && _props$theme31.fiveCard.useEquineRenditions ? "".concat(imageRenditionsEquine.tabletL.h, "px") : '';
}, function (props) {
  var _props$theme32;
  return (_props$theme32 = props.theme) !== null && _props$theme32 !== void 0 && _props$theme32.fiveCard.useEquineRenditions ? "calc(".concat((imageRenditionsEquine.tabletL.h / imageRenditionsEquine.tabletL.w).toFixed(4), " * (20vw ").concat(props.scrollbarWidth ? "- ".concat(props.scrollbarWidth / 5, "px") : '', "))") : "".concat(imageRenditions.desktop.h, "px");
}, function (props) {
  var _props$theme33;
  return (_props$theme33 = props.theme) !== null && _props$theme33 !== void 0 && _props$theme33.fiveCard.containerWidthFull ? "width: 100%;" : "width: 180px;";
}, breakPoints.desktopM, function (props) {
  var _props$theme34;
  return (_props$theme34 = props.theme) !== null && _props$theme34 !== void 0 && _props$theme34.fiveCard.useEquineRenditions ? "".concat(imageRenditionsEquine.desktop.h, "px") : '';
}, function (props) {
  var _props$theme35;
  return (_props$theme35 = props.theme) !== null && _props$theme35 !== void 0 && _props$theme35.fiveCard.useEquineRenditions ? "calc(".concat((imageRenditionsEquine.desktop.h / imageRenditionsEquine.desktop.w).toFixed(4), " * (20vw ").concat(props.scrollbarWidth ? "- ".concat(props.scrollbarWidth / 5, "px") : '', "))") : "".concat(imageRenditions.desktop.h, "px");
}, function (props) {
  var _props$theme36;
  return (_props$theme36 = props.theme) !== null && _props$theme36 !== void 0 && _props$theme36.fiveCard.containerWidthFull ? "width: 100%;" : "width: 198px;";
}, function (props) {
  var _props$theme37;
  return (_props$theme37 = props.theme) !== null && _props$theme37 !== void 0 && _props$theme37.fiveCard.containerWidthFull ? "width: 100%;\n      max-width: 450px;" : '';
}, function (props) {
  var _props$theme38;
  return (_props$theme38 = props.theme) === null || _props$theme38 === void 0 ? void 0 : _props$theme38.fiveCard.hoverColor;
}, function (props) {
  var _props$theme39;
  return (_props$theme39 = props.theme) !== null && _props$theme39 !== void 0 && _props$theme39.fiveCard.slickParaHeightIncreaseHover ? "height: 25%;\n              transition: 0.35s cubic-bezier(0.17, 0.67, 0.5, 1.03);" : '';
}, function (props) {
  var _props$theme40;
  return (_props$theme40 = props.theme) === null || _props$theme40 === void 0 ? void 0 : _props$theme40.fiveCard.cardBgColorHoverB;
}, function (props) {
  var _props$theme41, _props$theme42;
  return (_props$theme41 = props.theme) !== null && _props$theme41 !== void 0 && _props$theme41.fiveCard.cardCTAColorHoverB ? ".slick_header {\n          color: ".concat((_props$theme42 = props.theme) === null || _props$theme42 === void 0 ? void 0 : _props$theme42.fiveCard.cardCTAColorHoverB, ";\n        }") : '';
}, function (props) {
  var _props$theme43;
  return (_props$theme43 = props.theme) === null || _props$theme43 === void 0 ? void 0 : _props$theme43.fiveCard.iconColorHover;
}, function (props) {
  var _props$theme44;
  return (_props$theme44 = props.theme) === null || _props$theme44 === void 0 ? void 0 : _props$theme44.fiveCard.iconColorHover;
});
export default styles;