import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { getBackgroundStyle, isBgColorStyleLight, setNonCtaAnchorStyling } from '../../../utils/utilityFunction';
import fontColor from '../../../constants/globalConstants';
import constants from '../../../constants/globalConstants';
import textures from '../../../themes/textures';
var backgroundStyles = '';
var styles = css(["", ";margin:0;padding:40px 20px;.row{margin:0;.block{:nth-child(even){@media (min-width:", "){padding:0 20px;}}}}.block0{padding-left:0;margin-bottom:30px;@media (min-width:", "){margin-bottom:20px;}}p + ul,p + ol{margin-top:0;}h2,h3,h4,ul,ol,p{color:", ";a:not(.sg-btn-cpnt){display:inline;", "}}@media (min-width:", "){padding:40px 114px 20px;}@media (min-width:", "){padding:80px 0;}"], function (props) {
  if (props.styleType === 'threeColumnTextBlock') {
    var _props$theme, _props$theme2, _props$theme3;
    var _props$props = props.props,
      backgroundTheme = _props$props.backgroundTheme,
      overrideBackground = _props$props.overrideBackground,
      bladeBackgroundImage = _props$props.bladeBackgroundImage;
    var backgroundColor = overrideBackground && overrideBackground.colorBackground ? overrideBackground.colorBackground : backgroundTheme === undefined ? "#fff" : backgroundTheme === constants.light ? (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.fiftyFiftyCard.bgColorLight : (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.fiftyFiftyCard.bgColorDark;
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    var backgroundImageURLs = bladeBackgroundImage ? {
      desktopImage: bladeBackgroundImage.image && bladeBackgroundImage.image.url,
      mobileImage: bladeBackgroundImage.mobileImage && bladeBackgroundImage.mobileImage.url
    } : overrideBackground && overrideBackground.imageBackground ? {
      desktopImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url,
      mobileImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url
    } : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, backgroundColor, (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.palette, textures);
    return backgroundStyles;
  }
}, breakPoints.desktop, breakPoints.tabletP, function (props) {
  var _props$props2, _props$props3, _props$props4;
  if ((props === null || props === void 0 ? void 0 : (_props$props2 = props.props) === null || _props$props2 === void 0 ? void 0 : _props$props2.fontColor) === null) {
    var _props$theme4, _props$theme5;
    return isBgColorStyleLight(backgroundStyles) ? (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.fiftyFiftyCard.textColorDark : (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : _props$theme5.fiftyFiftyCard.textColorLight;
  }
  if ((props === null || props === void 0 ? void 0 : (_props$props3 = props.props) === null || _props$props3 === void 0 ? void 0 : _props$props3.fontColor) === fontColor.light) {
    var _props$theme6;
    return (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.fiftyFiftyCard.textColorLight;
  } else if ((props === null || props === void 0 ? void 0 : (_props$props4 = props.props) === null || _props$props4 === void 0 ? void 0 : _props$props4.fontColor) === fontColor.dark) {
    var _props$theme7;
    return (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.fiftyFiftyCard.textColorDark;
  }
}, function (props) {
  var _props$props5, _props$props6, _props$theme8;
  return (props === null || props === void 0 ? void 0 : (_props$props5 = props.props) === null || _props$props5 === void 0 ? void 0 : _props$props5.fontColor) === fontColor.light || ((_props$props6 = props.props) === null || _props$props6 === void 0 ? void 0 : _props$props6.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.fiftyFiftyCard) : '';
}, breakPoints.tabletP, breakPoints.desktop);
export default styles;