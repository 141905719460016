import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "count"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Star.style';
var StyleSection = styled.section.withConfig({
  componentId: "sc-9b2au6-0"
})(["", ";"], styles);
var Star = function Star(_ref) {
  var className = _ref.className,
    count = _ref.count,
    others = _objectWithoutProperties(_ref, _excluded);
  var starsCount = count || 5;
  var getStars = function getStars() {
    var stars = [];
    for (var i = 0; i < starsCount; i++) {
      stars.push(__jsx("div", {
        className: "clip-star",
        key: i
      }));
    }
    return stars;
  };
  return __jsx(StyleSection, {
    className: className
  }, __jsx("div", {
    className: "stars_block"
  }, getStars()));
};
Star.defaultProps = {
  count: 5,
  className: ''
};
export default styled(Star).withConfig({
  componentId: "sc-9b2au6-1"
})(["", ";"], styles);
export { Star as StarVanilla };