import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
import { setNonCtaAnchorStyling } from '../../../utils/utilityFunction';
var styles = css(["background-color:", ";.document-heading{margin-bottom:6px;color:", ";@media (min-width:", "){margin-bottom:20px;}@media (min-width:", "){margin-bottom:15px;}}&.download-form{padding:40px 20px;@media (min-width:", "){padding:60px 114px;}@media (min-width:", "){padding:80px 32px;}@media (min-width:", "){padding:80px 148px;}}.document-container{display:flex;flex-wrap:wrap;margin-top:60px;@media (min-width:", "){margin-top:80px;}}.download-description{width:70%;margin-bottom:40px;h3{margin-bottom:0.95rem;}h2,h3,h4,ul,ol,p{color:", ";}a:not(.sg-btn-cpnt){", "}@media (min-width:", "){width:60%;padding-right:50px;}}.download-section{width:100%;h4{color:", ";}@media (min-width:", "){width:30%;}}.document-sub-heading,.download-section .download-heading{color:", ";}.download-label{margin-left:15px;@media (min-width:", "){margin-left:20px;}}.download-list{display:flex;align-items:center;width:100%;margin-top:30px;.download-label{color:", ";}@media (min-width:", "){margin-top:40px;}}.download-icon{width:56px;height:56px;path{fill:", ";stroke:", ";}}@media (min-width:", "){padding:80px 0;}"], function (props) {
  var _props$theme;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : _props$theme.form.documentFormBgColor;
}, function (props) {
  var _props$theme2;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.form.textColorLight : '';
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.tabletP, breakPoints.desktop, breakPoints.desktopM, breakPoints.desktop, function (props) {
  var _props$theme3;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.form.textColorLight : '';
}, function (props) {
  var _props$documentFormsD, _props$theme4;
  return ((_props$documentFormsD = props.documentFormsData) === null || _props$documentFormsD === void 0 ? void 0 : _props$documentFormsD.fontColor) === fontColor.light ? setNonCtaAnchorStyling((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.form) : '';
}, breakPoints.desktop, function (props) {
  var _props$theme5, _props$theme6;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && _props$theme5.form.documentFormH4Color ? (_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : _props$theme6.form.documentFormH4Color : '';
}, breakPoints.desktop, function (props) {
  var _props$theme7;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? (_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : _props$theme7.form.textColorLight : '';
}, breakPoints.desktop, function (props) {
  var _props$theme8, _props$theme9;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.form.textColorLight : (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : _props$theme9.form.linkColor;
}, breakPoints.desktop, function (props) {
  var _props$theme10, _props$theme11;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? (_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : _props$theme10.form.textColorLight : (_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.form.linkColor;
}, function (props) {
  var _props$theme12, _props$theme13;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? (_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : _props$theme12.form.textColorLight : (_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.form.linkColor;
}, breakPoints.desktop);
export default styles;